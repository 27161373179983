html.dark  {
	.tab-content ,.nav-tabs li .nav-link 
	,.nav-tabs li .nav-link:hover {	
		background: transparent;
		border-color: $dark-border-color;
	}
	
	.nav-tabs li.active .nav-link, .nav-tabs li.active .nav-link:hover, .nav-tabs li.active .nav-link:focus{
		background: $dark-focused-tab-bg;
		border-color: $dark-border-color;
	}

	.border {
		border-color: $dark-border-color !important;
	}

	.alter {
		background-color: transparent;
		border-top: 1px solid $dark-border-color;
		border-bottom: 1px solid $dark-border-color;
	}

	.bs-datepicker {
		background-color: #525151;
		box-shadow: 0 0 10px 0 #000;
		.bs-datepicker-head {
			background-color: #6e9ca8 !important;
		}
		.bs-datepicker-body .days.weeks {
			thead {
				background-color: #314850 !important;
				th {
					color: rgb(202, 202, 202) !important;
				}
			}
			td {
				.is-other-month {
					color: rgb(153, 153, 153) !important;
				}
				span { 
					&:hover {
						background-color: #314850;
					}
				}
			}
		}		  
	}

	.ui-select-choices-row a , .dealership label {
		color: #ddd !important;
	}	

	table tr td {
		color: white;
	}

	.dropdown-menu .dropdown-item {
		color: white;	
	}	
}
.bs-datepicker-head {
	background-color: #8fb9c5 !important;
	span {
		font-size: 1rem;
	}
}
.bs-datepicker-body .days.weeks { 
	thead {
		background-color: #eaf6fa !important;
		th {
			color: rgb(110, 117, 122) !important;
		}
  }
  tbody {
	  tr {
		  &:hover {
			background-color:transparent;	
		  }
	  }
  }
}

.scrollable {
	overflow-y: scroll;
	flex: 1 0 1px;
}

.table-scrollable{
	width: 100%;
	tbody{
	  height:300px;
	  overflow-y:auto;
	  width: 100%;
	  }
	thead,tbody,tr,td,th{
	  display:flow-root;
	}
	tbody{
	  td{
		float:left;
	  }
	}
	thead {
	  tr{
		th{
		  float:left;
		  border: 0;
		}
	  }
	}
  }
  
  

.my-custom-scrollbar {
	position: relative;
	height: 200px;
	overflow: auto;
}

.table-wrapper-scroll-y {
	display: block;
}

.border-dashed {
	border : 1px solid #ccc;
}
.pointer {
	cursor: pointer;
}