/* You can add global styles to this file, and also import other style files */
@import "assets/css/variables.scss";
@import "assets/vendor/animate/animate.css";
@import "assets/vendor/font-awesome/css/all.min.css";
@import "assets/vendor/magnific-popup/magnific-popup.css";
@import "assets/vendor/bootstrap-datepicker/css/bootstrap-datepicker3.css";

@import "assets/vendor/owl.carousel/assets/owl.carousel.min.css";
@import "assets/vendor/owl.carousel/assets/owl.theme.default.min.css";

@import "assets/css/theme.css";
@import "assets/css/theme-extended.scss";
@import "assets/css/skins/default.css";

//@import "~@ng-select/ng-select/themes/material.theme.css";

.form-control.ng-invalid:not(form) {
  &.with-addon {
    border-left: 5px solid #a94442;

    &.with-addon {
      border-left: none;
    }
  }
}

.ns {
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.disableIcon {
  opacity: 0.6;
}

p.button-group {
  text-align: right;
  margin-bottom: 0;

  .btn {
    margin-left: 5px;
    min-width: 135px;
  }

  .btn.no-min-width {
    min-width: unset;
  }
}

.background-danger {
  background-color: red;
}

.font-gold {
  color: #fff700;
}

.logo {
  height: 46px;
  width: 46px;
  margin: 0 !important;
  padding-top: 5%;
  background: url(./assets/img/obc_logo_light_90px.png) no-repeat;
  background-repeat: no-repeat;
  background-size: contain;
  margin-left: 5px !important;
  margin-top: 3px !important;
}

.large-logo {
  margin: 0 !important;
  height: 128px;
  width: 128px;
  padding-top: 5%;
  background: url(./assets/img/obc_logo.png) no-repeat;
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  margin-left: 5px !important;
  margin-top: 3px !important;
}

.cursor-pointer:hover {
  cursor: pointer;
}

$theme-primary-color: #ff8c00;
$theme-light-primary-color: #ff8c0080;
$theme-danger-color: #e12425;
$theme-light-danger-color: #e1242580;
$theme-success-color: #4bc44c;
$theme-light-success-color: #4bc44c80;
$theme-info-color: #15a9ff;
$theme-light-info-color: #15a9ff80;
$theme-active-info-color: #0062cc;

$theme-white-color: #fff;
$theme-dark-color: #1d2127;
$theme-light-dark-color: #585858;
$theme-black-color: #000;

.chart {
  background-color: white;
  padding: 16px;
  border-radius: 0.25rem;
  border: 1px solid rgba(0, 0, 0, 0.125);
}

html {

  obc-secure,
  obc-supplier-join-to-company,
  obc-anonymous {
    obc-top-bar {
      header {
        background-color: $theme-primary-color !important;
        border-bottom-color: $theme-primary-color !important;
        border-top-color: $theme-primary-color !important;

        .logo-container {
          .brand {
            position: absolute;
            // margin-left: 60px;
            margin-bottom: 0;
            line-height: 56px;
            font-family: sans-serif;
            color: $theme-white-color;

            span {
              color: $theme-white-color !important;
            }
          }

          .logo {
            background: url(./assets/img/1_90w.png) no-repeat;
            background-repeat: no-repeat;
            background-size: contain;
          }
        }

        .userbox {

          .name,
          .role {
            max-width: 200px;
          }

          .dropdown-menu {
            background-color: $theme-primary-color !important;
          }
        }

        a,
        span,
        .custom-caret,
        span.separator {
          color: $theme-white-color !important;
          font-weight: 500;
        }
      }
    }

    obc-side-bar {

      a,
      .sidebar-title {
        color: $theme-white-color !important;
        font-size: 16px !important;
        font-weight: 500 !important;
      }

      aside {
        .nano {

          //height: 100%;
          .nano-content {
            background: $theme-light-dark-color;
            box-shadow: -5px 0 0 $theme-light-dark-color inset;
          }
        }

        background: $theme-light-dark-color !important;

        .sidebar-header {
          .sidebar-title {
            color: $theme-white-color !important;
            background: $theme-light-dark-color !important;
          }

          .sidebar-toggle {
            background: $theme-light-dark-color !important;

            i {
              color: $theme-white-color !important;
            }
          }
        }

        nav {
          ul {
            margin-right: 0 !important;

            li {
              a {
                background: $theme-light-dark-color !important;
              }

              a:hover {
                background: $theme-dark-color !important;
              }
            }

            li.nav-active a {
              box-shadow: 2px 0 0 $theme-dark-color inset !important;
            }
          }

          ul.nav-children {
            padding: 0 !important;

            li {
              a {
                line-height: 30px;
                height: 49.7px;
              }
            }
          }
        }
      }
    }

    section.content-body {
      .btn-primary {
        background-color: $theme-primary-color !important;
        border-color: $theme-light-primary-color !important;
      }

      .btn-primary:hover:not([disabled]) {
        background-color: $theme-light-primary-color !important;
        border-color: $theme-light-primary-color !important;
      }

      .btn-danger {
        background-color: $theme-danger-color !important;
        border-color: $theme-light-danger-color !important;
      }

      .btn-danger:hover:not([disabled]) {
        background-color: $theme-light-danger-color !important;
        border-color: $theme-light-danger-color !important;
      }

      .btn-info {
        background-color: $theme-info-color !important;
        border-color: $theme-light-info-color !important;
      }

      .btn-info.active {
        background-color: $theme-active-info-color !important;
        border-color: $theme-light-info-color !important;
      }

      .btn-info:hover:not([disabled]) {
        background-color: $theme-light-info-color !important;
        border-color: $theme-light-info-color !important;
      }

      .btn-success {
        background-color: $theme-success-color !important;
        border-color: $theme-light-success-color !important;
      }

      .btn-success:hover:not([disabled]) {
        background-color: $theme-light-success-color !important;
        border-color: $theme-light-success-color !important;
      }

      header.page-header {
        border-left: none;
        box-shadow: 1px 3px 0 1px $theme-light-dark-color !important;
        background-color: $theme-light-dark-color !important;

        h2 {
          border-bottom-color: $theme-dark-color;
        }
      }
    }

    a {
      text-decoration: none !important;
      color: $theme-dark-color !important;
    }

    .card {
      .card-header {

        h1,
        h2,
        h3,
        h4,
        h5 {
          font-size: 1.25rem;
          line-height: 1.25rem;
          margin-bottom: 6px;
        }

        .card-header-button {
          position: absolute;
          right: 10px;
          top: 6px;
        }
      }

      .card-body {
        box-shadow: none;
      }
    }

    .card-featured-left {
      border-color: rgba(0, 0, 0, 0.125) !important;
    }

    table {
      thead {
        tr {
          background-color: $theme-primary-color;
          color: $theme-white-color;
        }
      }
    }
  }

  .modal-table {
    thead {
      tr {
        background-color: $theme-primary-color;
        color: $theme-white-color;
      }
    }
  }

  .nav-tabs li .nav-link,
  .nav-tabs li .nav-link:hover {
    background: #58585847;
    border-bottom: none;
    border-left: 1px solid #ccc;
    border-right: 1px solid #ccc;
    border-top: 2px solid #ccc;
    color: white !important;
    font-weight: 500;
  }

  .nav-tabs li.active .nav-link,
  .nav-tabs li.active .nav-link:hover,
  .nav-tabs li.active .nav-link:focus {
    border-top: 2px solid #585858;
    border-left: 1px solid #585858;
    border-right: 1px solid #585858;
    font-weight: 600;
    color: #585858 !important;
  }

  .link-active a {
    background-color: grey !important;
    font-weight: bold;
  }
}

.page-item.disabled .page-link {
  background-color: #f0f0f0 !important;
  color: #999 !important;
}

.pagination>.active>a,
.pagination>.active>span,
.pagination>.active>a:hover,
.pagination>.active>span:hover,
.pagination>.active>a:focus,
.pagination>.active>span:focus,
.pagination .page-item.active .page-link {
  color: #fff !important;
}

.ng-select.ng-select-multiple .ng-select-container .ng-value-container .ng-value {
  border: 1px solid #ccc;
  border-radius: 3px;
}

.light-theme.text-warning {
  color: #d5a30a !important
}

.obc-badge.badge-success {
  //Border & Text: Colour #CF2B2B 1px Weight Stroke, Regular Text Style Not Bold, Inside Fill Colour: #FCD5D5
  background-color: #CCE4D7 !important;
  border: 1px solid #007837 !important;
  color: #007837 !important;
}

.obc-badge.badge-warning {
  //Border & Text: Colour #CF2B2B 1px Weight Stroke, Regular Text Style Not Bold, Inside Fill Colour: #FCD5D5
  background-color: #FFE9CE !important;
  border: 1px solid #C87511 !important;
  color: #C87511 !important;
}

.obc-badge.badge-danger {
  //Border & Text: Colour #CF2B2B 1px Weight Stroke, Regular Text Style Not Bold, Inside Fill Colour: #FCD5D5
  background-color: #FCD5D5 !important;
  border: 1px solid #CF2B2B !important;
  color: #CF2B2B !important;
}

html.dark {
  p {
    color: #d8d8d8;
  }

  .text-muted {
    color: #99a2bd !important;
  }

  .radio-primary input[type="radio"]:checked+label:after,
  .radio-primary input[type="radio"]:checked+label:after {
    background: #d5d5d5
  }

  .radio-custom label:before {
    background: #3f4c4c !important;
    border-color: #6b727c !important;
  }

  .site-selector .ng-select-container .ng-value,
  .site-selector .ng-select-container .ng-placeholder {
    color: #dcdcdc !important;
  }

  .ng-select.ng-select-multiple .ng-select-container .ng-value-container .ng-value .ng-value-icon.left {
    border-right: 1px solid #8f8b8c;
  }

  .ng-select.ng-select-multiple .ng-select-container .ng-value-container .ng-input>input {
    color: #fff;
  }

  .ng-select.ng-select-multiple .ng-select-container .ng-value-container .ng-value {
    border: 1px solid #676767;
    border-radius: 3px;
    color: #e7e7e7;
    background-color: #5d5c5c;
  }

  .ng-select.ng-select-multiple .ng-select-container .ng-value-container .ng-value .ng-value-icon:hover {
    background: #312445;
  }

  .ng-select .ng-select-container {
    background-color: #21262d;
    border-color: #646c7c;
    color: white;
  }

  body {
    color: white;
  }

  table {
    color: #fff;
  }

  form,
  .card {
    background: #2e353e;
  }

  .card {
    border-color: lightgray;
  }

  .alert-default {
    background: #242223;
    color: white;
  }

  .alert-default a {
    color: white;
  }

  .popover-body {
    color: white;
  }

  .input-group-text {
    background-color: #535454;
    border: 1px solid #4a4a4a;
    color: #eee;
  }

  input[type="time"]::-webkit-calendar-picker-indicator {
    filter: invert(88%) sepia(13%) saturate(3207%) hue-rotate(130deg) brightness(95%) contrast(80%);
  }

  .ng-select .ng-select-container .ng-value-container .ng-input>input {
    color: #fff;
  }

  .ng-dropdown-panel .ng-dropdown-panel-items .ng-option.ng-option-disabled {
    color: #4e4b4b;
  }

  .alert-info {
    color: #fafafa;
    background-color: #47727b;
    border-color: #23545b;
  }

  obc-secure,
  obc-supplier-join-to-company,
  obc-anonymous {
    obc-top-bar {
      header {
        background-color: $theme-black-color !important;
        border-bottom-color: $theme-black-color !important;
        border-top-color: $theme-black-color !important;

        .logo-container {
          .brand {
            color: $theme-white-color;

            span {
              color: $theme-white-color !important;
            }
          }

          .logo {
            background: url(./assets/img/obc_logo_90px.png) no-repeat;
            background-repeat: no-repeat;
            background-size: contain;
          }
        }

        .userbox {
          .dropdown-menu {
            background-color: $theme-dark-color !important;
          }
        }

        a,
        span,
        span.separator {
          color: $theme-white-color !important;
          font-weight: 500;
        }

        .custom-caret::before {
          color: $theme-white-color !important;
        }
      }
    }

    a,
    i.fa {
      color: $theme-white-color !important;
    }

    input.form-control,
    select.form-control {
      //border-color: $theme-light-dark-color !important;
      background-color: $theme-dark-color;
    }

    ul.nav-tabs {
      li.active {
        a {
          background-color: $theme-dark-color !important;
        }
      }
    }

    table {
      thead {
        tr {
          background-color: $theme-dark-color;
          color: $theme-white-color;
        }
      }
    }

    .chart {
      border: 1px solid white;
      background-color: #2e353e;
      color: white;
    }

    .card.anonymous-checkout-card,
    .anonymous-checkout-questions {

      p,
      span {
        color: #eee;
      }
    }
  }

  .table-bordered th,
  .table-bordered td {
    border-color: #414141 !important;
    border-width: 0.5px;
  }

  .btn-dark {
    border-color: #535960 #6b6b6b #727272;
  }

  .form-control {
    border-color: #646c7c;
  }

  button.close {
    color: #fff;
  }

  .heading-primary,
  .lnk-primary,
  .text-color-primary,
  .text-primary {
    color: #a8ddfa !important;
  }

  .btn-link {
    color: #8ba7c4
  }

  .alert-warning {
    color: #f9ff00;
    background-color: #272727;
    border-color: #584f2e;
  }

  .alert-success {
    color: #8dd320;
    background-color: transparent;
    border-color: #26893c;
  }

  .alert-danger {
    color: #ce1324;
    background-color: transparent;
    border-color: #883a42;
  }

  .btn-primary.disabled,
  .btn-primary:disabled {
    color: #eee;
  }

  .table>thead>tr>th,
  .table>tbody>tr>th,
  .table>tfoot>tr>th,
  .table>thead>tr>td,
  .table>tbody>tr>td,
  .table>tfoot>tr>td,
  .table-bordered {
    border-color: #606770;
  }

  .alert h1,
  .alert .h1,
  .alert h2,
  .alert .h2,
  .alert h3,
  .alert .h3,
  .alert h4,
  .alert .h4,
  .alert h5,
  .alert .h5,
  .alert h6,
  .alert .h6 {
    color: #d5d5d5
  }

  .filter-card {
    border: 1px solid #675a5a;
    padding: 6px;
    border-radius: 5px;
    margin: 0 5px;
  }

  .modal-table {
    thead {
      tr {
        background-color: $theme-dark-color;
        color: $theme-white-color;
      }
    }
  }

  .nav-tabs li.active .nav-link,
  .nav-tabs li.active .nav-link:hover,
  .nav-tabs li.active .nav-link:focus {
    color: white !important;
    background-color: $theme-dark-color;
  }

  .dropdown-menu .dropdown-item:hover {
    color: white;
    background-color: $theme-dark-color;
  }

  .page-item.disabled .page-link {
    background-color: #2f2f2f !important;
    color: white !important;
  }

  .pagination>li>a,
  html.dark .pagination>li>span {
    border-color: #7c7c7c !important;
  }

  .pagination-page .page-item .active {
    border-color: white !important;
  }

  popover-container {
    background-color: $theme-dark-color;
    color: $theme-white-color;

    .popover-header {
      background-color: $theme-dark-color;
      color: $theme-white-color;
    }
  }

  .owl-dt-control-button-content {
    color: black;
  }

  .import-site .answer {
    color: greenyellow;
  }

  .import-site tr.bk-warning td {
    background-color: darkred;
  }

  .list-group-item {
    background-color: inherit;

  }

  .csv-popover {
    border-color: #777;

    form {
      background: transparent !important;
    }

    .custom-radio,
    form label {
      color: #f8f8f8;
    }
  }

  .csv-header-popover {
    border: 1px solid #777;

    .no-data {
      color: #f8f8f8;
    }
  }
}

table.obc-table {
  thead {
    tr {
      background-color: $theme-primary-color;
      color: $theme-white-color;
    }
  }
}

html.dark table.obc-table {
  thead {
    tr {
      background-color: $theme-dark-color;
      color: $theme-white-color;
    }
  }
}

.form-control.ng-valid,
ng-select.supplier-selector.ng-valid {
  border-left: 5px solid #42a948 !important;
  //border-left: none;
}

.form-control.ng-invalid,
ng-select.supplier-selector.ng-invalid {
  border-left: 5px solid #a94442 !important;
  //border-left: none;
}

tabset.no-tab-content {
  div.tab-content {
    display: none !important;
  }
}

.input-group-prepend {

  // min-width: 120px;
  .input-group-text {
    width: 100%;
  }
}

.info {
  .tooltip-inner {
    background-color: #0088cc !important;
    color: #fff !important;
  }

  .tooltip-arrow:before,
  .tooltip-arrow {
    border-top-color: #0088cc !important;
  }
}

.danger {
  .tooltip-inner {
    background-color: #b90523 !important;
    color: #fff !important;
  }

  .tooltip-arrow:before,
  .tooltip-arrow {
    border-top-color: #b90523 !important;
  }
}

.success {
  .tooltip-inner {
    background-color: #388f28 !important;
    color: #fff !important;
  }

  .tooltip-arrow:before,
  .tooltip-arrow {
    border-top-color: #388f28 !important;
  }
}

i.question-status {
  position: relative;
  top: 1px;
}

.anonymouse-content-body {
  padding: 20px;
}

button.card-header-button {
  position: absolute;
  right: 10px;
  top: 10px !important;
}

.company-tag {
  font-size: 10px;
  padding: 2px 6px;
  background-color: yellowgreen;
  border-radius: 3px;
  margin-right: 2.5px;
  color: white;
  white-space: nowrap;

  i {
    position: relative;
    top: 1px;
    color: white;
  }
}

.company-tag.lg {
  font-size: medium;
}

.bk-light-danger {
  background-color: #ffcccc !important;

  td {
    background-color: #ffcccc !important;
  }
}

.danger-panel {
  .panel-heading {
    background-color: red;

    button {
      color: white;
      text-decoration: none;
    }
  }
}

.info-panel {
  .panel-heading {
    background-color: #2baab1;

    button {
      color: white;
      text-decoration: none;
    }
  }
}

.sortable {
  cursor: pointer;
  text-decoration: underline;
}

.page-header {
  z-index: 1000 !important;
}

ul.simple-post-list li {
  padding-right: 5px;
  padding-left: 5px;
}

ul.simple-post-list li:nth-of-type(odd) {
  background-color: rgba(0, 0, 0, 0.05);
}

ul.simple-post-list.no-background li {
  background-color: inherit;
}

ul.simple-post-list li:hover {
  background-color: rgba(0, 0, 0, 0.1);
}

.card {
  opacity: 0.9 !important;
}

typeahead-container {
  z-index: 1060 !important; // over modals
}

.text-w700 {
  font-weight: 700;
}

.custom-large-button {
  padding: 0 !important;
  border-width: 5px !important;
  border-radius: 8px !important;

  span {
    vertical-align: bottom;
    padding: 0px 10px;
  }

  i {
    margin-left: 5px;
    padding: 5px 10px;
    position: relative;
    font-size: 36px;
    top: 0;
    right: 5px;
  }

  img {
    margin-left: 5px;
    margin: 5px 10px;
    width: 36px;
    height: 36px;
  }
}

.dashed-bordered {
  width: 100%;
  padding: 2px 10px;
  border-radius: 5px;
  border: 1px dashed rgba(0, 0, 0, 0.2);
}

.card-body-bk {
  position: absolute;
  /* top: 50%; */
  /* height: 50%; */
  /* transform: translate(-50%, -50%); */
  opacity: 0.75;
  /* left: 25%; */
  width: 100%;
  -o-object-fit: contain;
  object-fit: unset;
  height: 100%;
  /* padding: 25px; */
  /* transform: scale(0.75); */
  padding-right: 30px;
}

.bk-image-container {
  left: 0;
  position: absolute;
  width: 100%;
  height: 100%;
  padding-right: 15px !important;
  padding-left: 15px !important;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  img {
    width: 60%;
    height: 300px;
    max-height: 60%;
  }
}

.full-width {
  width: 100%;
}

.cdk-overlay-container {
  position: fixed;
  pointer-events: none;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  display: flex;
  position: absolute;
  z-index: 9999999 !important;
}

.cdk-global-overlay-wrapper,
.cdk-overlay-container {
  pointer-events: none;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  display: flex;
  position: absolute;
  z-index: 9999999 !important;
}

.fw {
  width: 100%;
}

.fh {
  height: 100%;
}

.form-control.inline {
  display: inline !important;
  width: auto;
}

.form-control.invalid-state {
  display: inline !important;
  border-width: 0 0 0 5px !important;
  padding-right: 0 !important;
  padding-left: 0 !important;
}

.bold {
  font-weight: bold;
}

.dark {
  .academy-tooltip {

    .tooltip-arrow,
    .tooltip-inner,
    .tooltip-arrow::before {
      color: black;
      background-color: white;
      border-color: white !important;
    }

    .content {
      color: black;
      background-color: white;
    }
  }

  .item-title {
    padding: 0.25rem !important;
    background-color: lightskyblue;
    font-weight: bold;
  }
}

.item-title {
  padding: 0.25rem !important;
  background-color: lightskyblue;
  font-weight: bold;
}

.site-selector {
  .ng-select-container {
    height: 40px;

    .ng-value,
    .ng-placeholder {
      font-weight: bold;
      color: #333 !important;
    }
  }
}

.overflow-visible {
  overflow: visible !important;
}

.popover-title {
  color: $theme-dark-color;
}

.closable-button {
  top: 0px;
  position: relative;
  right: -5px;
}

.closable-button.small-widget {
  right: 5px;
}

.filter-bordered {
  border: 1px solid #393d44;
  padding: 6px;
  border-radius: 5px;

  .font-danger {
    color: $theme-danger-color !important;
  }
}

.filter-card {
  border: 1px solid #393d44;
  padding: 6px;
  border-radius: 5px;
  margin: 0 5px;

  .font-danger {
    color: $theme-danger-color !important;
  }
}

.bold-buttom-border {
  border-bottom: 3px solid $theme-danger-color !important;
}

button[accordion-heading] {
  color: (0, 0, 0, 0.85);
}

.header-text-color {
  color: $theme-dark-color;
}

.dark {
  .header-text-color {
    color: $theme-white-color;
  }

  button[accordion-heading] {
    color: white;
  }

  .filter-card {
    background-color: $theme-dark-color;
    border: 1px solid #ffffff;
    padding: 6px;
    color: white;
    border-radius: 5px;
    margin: 0 5px;

    .font-danger {
      color: $theme-danger-color !important;
    }
  }

  .filter-bordered {
    border: 1px solid white;
    background-color: $theme-dark-color;
    color: white;
    padding: 6px;
    border-radius: 5px;

    .font-danger {
      color: $theme-danger-color !important;
    }
  }

  .bold-buttom-border {
    border-bottom: 3px solid $theme-white-color !important;
  }

  .modal-close-button {
    color: white !important;
  }
}

popover-container.hide-title {
  max-width: none !important;

  .popover-title {
    display: none !important;
  }
}

.color-white {
  color: white;
}

.sortable-item {
  padding: 0.75rem;
  cursor: grab;
  vertical-align: top;
  transition: border-radius 300ms;
}

.sortable-item-active {
  background-color: #e6e6e6;
  border: 1px solid orange;
  border-radius: 10px;
}

.sortable-wrapper {
  min-height: 100px;
}

@media only screen and (max-width: 600px) {
  .custom-mobile-flex-column {
    display: flex;
    flex-direction: column !important;
  }
}

.no-cursor {
  cursor: default;
}

/* Rich Text Editor Global Styles */
.tui-editor-defaultUI .te-switch-button {
  display: none;
}

.te-editor>.tui-editor-contents table td {
  color: #222;
}

.tui-popup-body li,
.tui-popup-body li * {
  color: #222 !important;
}

.dark :not(.te-editor)>.tui-editor-contents * {
  color: white;
}

accordion.site-management {
  .header-icon-title {
    display: flex;
    align-items: center;
  }

  .header-icon-title i {
    width: 25px;
    text-align: center;
    margin-right: 15px;
  }

  .accordion-subtitle {
    font-size: 18px;
    margin-top: 15px;
  }

  accordion-group>.card {
    border-bottom: none !important;
    border-radius: 0 !important;
  }

  accordion-group.panel-open:last-child {
    border-bottom: 1px solid rgba(0, 0, 0, 0.125);
  }

  accordion-group div[accordion-heading] {
    font-size: 1.25rem;
    line-height: 1.25rem;
    margin-bottom: 6px;
    margin-top: 6px;
  }

  accordion-group .invalid-sign {
    opacity: 0;
    transform: scale(0);
    transition: transform 0.7s cubic-bezier(0.75, -0.5, 0, 1.75);
    color: red;
    height: 0;
  }

  accordion-group .invalid-sign.visible {
    opacity: 1;
    height: 17px;
    transition: all 0.7s cubic-bezier(0.75, -0.5, 0, 1.75);
    transform: scale(1.2);
  }

  accordion-group.danger-zone .panel-heading.card-header {
    background: red;
    color: white;
    border-radius: 0 !important;
  }

  accordion-group.danger-zone .panel {
    border: 1px solid red;
  }

  accordion-group.danger-zone .panel-body {
    border-bottom: 1px solid red;
    border-radius: 0 !important;
  }
}

.modal .scrollable-list {
  max-height: 600px;
  overflow: auto;
}

.cdk-global-overlay-wrapper,
.cdk-overlay-container {
  position: fixed;
}

.signature-bg {
  background-color: white;
}

timepicker td.form-group {
  padding-bottom: 0 !important;
}

.modal-close-button {
  position: absolute;
  right: 10px;
  top: 10px;
  z-index: 9999;
  font-size: 15px;
  color: black !important;
}

.card.check-in-supplier-block {
  background-color: #f1f0ff;
  border: 1px solid #8796d0;
}

html.dark .card.check-in-supplier-block {
  background-color: #f1f0ff;
  border: 1px solid #8796d0;
}

.announcement-visit .form-check-input {
  width: 17px;
  height: 17px;
}

obc-supplier-join-to-company {
  .content-body {
    padding: 0 !important;
  }

  .inner-wrapper {
    padding-top: 35px !important;
  }
}

html.sidebar-left-collapsed obc-supplier-join-to-company {
  .content-body {
    margin-left: 0 !important;
  }
}

html.dark obc-supplier-join-to-company .text-color-quaternary {
  color: #5cb979 !important;
}

html:not(.dark) obc-supplier-join-to-company .--bg {
  background: #f5f5f5;
}

.panel-heading-hide .panel-heading {
  display: none !important;
}

.modal-full-width {
  max-width: 90% !important;
}

.comment-record:nth-child(odd) {
  background: #f5f5f5;
}

.comment-record:nth-child(even) {
  background: #e0e0e0;
}

.dark .comment-record:nth-child(odd) {
  background: #585858;
}

.dark .comment-record:nth-child(even) {
  background: #393939;
}



.image-block-container {
  width: 160px;
  height: 160px;
  display: inline-block;
  box-shadow: 0 0 12px 11px rgb(0 0 0 / 4%);
  border-radius: 15px;
  overflow: hidden;
  margin: 10px;
  border: 1px solid #e4e4e4;
}

html.dark .image-block-container {
  border: 1px solid #616167;
  background: #4f4f64;
}

.image-block-container img {
  max-width: 300px;
  max-height: 300px;
  object-fit: contain;
}

html.dark popover-container {
  background: #52565c;
}

html.dark popover-container .popover-header {
  background-color: #4d4f51;
}

.popover .arrow {
  height: 0 !important;
}

html.dark .bs-popover-top>.arrow::after,
.bs-popover-auto[x-placement^="top"]>.arrow::after {
  border-top-color: #52565c;
}

html.dark .bs-popover-left>.arrow::after,
.bs-popover-auto[x-placement^="left"]>.arrow::after {
  border-left-color: #52565c;
}

html.dark .bs-popover-bottom>.arrow::after,
.bs-popover-auto[x-placement^="bottom"]>.arrow::after {
  border-bottom-color: #52565c;
}

html.dark .bs-popover-right>.arrow::after,
.bs-popover-auto[x-placement^="right"]>.arrow::after {
  border-right-color: #52565c;
}

html.dark .bs-popover-bottom .popover-header::before,
html.dark .bs-popover-auto[x-placement^="bottom"] .popover-header::before {
  border-bottom: 1px solid transparent;
}

.obc-pagination-wrapper ul.pagination {
  margin-bottom: 0 !important;
}

.grid-pagination-row {
  background: #fff;
}

html.dark .grid-pagination-row {
  background: transparent !important;
}

.grecaptcha-badge {
  visibility: hidden;
}

.form-control:disabled,
ng-select:disabled {
  border-left: 5px solid #999 !important;
}


.danger-dropdown-item {
  color: #e70202 !important;
}

html:not(.dark) .danger-dropdown-item:active {
  background: #ffc2c2 !important;
}

html.dark .danger-dropdown-item {
  color: #ff6868 !important;
}

.pdf-overlay .body {
  min-height: auto;
}

.modal {
  bottom: -5px;
  height: auto !important;
}

.modal-full-screen {
  width: 100% !important;
  padding: 0 !important;
  margin: 0 !important;
  height: 100% !important;
  max-width: 100% !important;
}

.modal-full-screen .modal-content {
  border-radius: 0 !important;
  border: none !important;
}

.no-scroll::-webkit-scrollbar {
  display: none;
}

.no-scroll {
  -ms-overflow-style: none;
  /* IE and Edge */
  scrollbar-width: none;
  /* Firefox */
}

.csv-header-item-title {
  color: #666;
  margin-bottom: 15px;
}

.csv-header-popover hr {
  opacity: 0.5;
}

.csv-header-popover span.fa::before {
  color: #444;
}

obc-top-bar .dropdown-menu {
  border: none;
}

html.dark {
  obc-top-bar .csv-header-item-title {
    color: #ddd !important;
  }

  .csv-header-popover hr {
    border-color: white;
  }

  .csv-header-popover span.fa::before {
    color: #dddddd;
  }
}


obc-question-items-renderer .dropdown-menu {
  box-shadow: 0 0 13px -3.5px rgba(0, 0, 0, 0.35);
  transform: translate(-2%, -170%) !important;
  min-width: 140px;
  padding: 9px 8px;
  margin: 0;
}

.dark {
  obc-question-items-renderer .dropdown-menu {
    border: 1px solid #999;
  }

  obc-question-items-renderer .checkbox-wrapper .check-box {
    border-color: #bbb !important;
  }

  obc-question-items-renderer .checkbox-wrapper .check-box::before,
  obc-question-items-renderer .checkbox-wrapper .check-box::after {
    background-color: #bbb !important;
  }

  obc-question-items-renderer .styles-header {
    border-bottom-color: #666;
  }

  obc-question-items-renderer textarea {
    color: #fff;
  }

  obc-question-items-renderer textarea::placeholder {
    color: #c3d7d4;
  }

  .profile-item-value {
    color: #eee !important;
  }

  .fixed-menu {
    box-shadow: 0 4px 27px -12px rgba(100, 100, 100, 0.5) !important;
    background: #312a2a !important;
  }

  .fixed-menu-body {
    background: #312a2a !important;
    box-shadow: 0 4px 27px -12px rgba(100, 100, 100, 0.5) !important;
  }

  .revision-requested {
    color: #999 !important;
  }
}

.no-right-border-radius {
  border-top-right-radius: 0 !important;
  border-bottom-right-radius: 0 !important;
  }
.modal-90p.modal-dialog {
  width: 90% !important;
  max-width: 90% !important;
}
